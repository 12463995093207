import React from "react"
import styled from "styled-components"
import { breakpoints, device } from "../../constants/breakpoints"
import { Doctors } from "../../constants/doctors"
import { CardSingle } from "../../containers/home/cards"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import SuggestedCard from "./SuggestedCard"

const Container = styled.div`
  border-radius: 12px;
  background: ${({ theme }) => theme.lightocean};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tabletSm} {
    border-radius: 0px;
  }
`

const Title = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.blue};
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5px;
  @media ${device.desktop} {
    font-size: 20px;
  }
  @media ${device.tablet} {
    font-size: 22px;
  }
`

const SubTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  @media ${device.desktop} {
    font-size: 16px;
  }
  @media ${device.tablet} {
    font-size: 18px;
  }
`

const SuggestedCol = ({ title, doctors = Doctors, ...restProps }) => {
  const { width } = useWindowDimensions()
  return (
    <Container {...restProps}>
      <Title>Gli specialisti {title && "in"}</Title>
      <SubTitle>{title} vicino a te</SubTitle>

      {width < breakpoints.tablet ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {doctors.slice(0, 3).map((d, i) => (
            <CardSingle
              doctor={d}
              style={{ margin: "auto", marginTop: i == 0 ? 0 : 30 }}
            />
          ))}
        </div>
      ) : (
        doctors.slice(0, 2).map(d => <SuggestedCard doctor={d} />)
      )}
    </Container>
  )
}

export default SuggestedCol
