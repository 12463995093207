import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchChirurghiHome, selectChirurghiHome } from "../redux/homeSlice"

export default function useChirurghiHome() {
  const dispatch = useDispatch()
  const chirurghiHome = useSelector(selectChirurghiHome)

  useEffect(() => {
    dispatch(fetchChirurghiHome())
  }, [])

  return chirurghiHome
}
